<template>
  <div class="about">
    <Header ref="pagedetail"></Header>
    <main class="layout content-inner">
      <div id="page">
        <div id="article-container">
          <center>
            <a class="fancybox">
              <img src="https://blog.likepoems.com/images/author.jpg" />
            </a>
          </center>
          <center class="authorname">一缕阳光</center>
          <div class="box">
            <p class="infotitle">🎯博主信息</p>
            <div class="checkbox red checked">
              <input type="radio" checked="checked" /> 四川 / 学生 / 软工 / 大三
            </div>
            <div class="checkbox red checked">
              <input type="radio" checked="checked" /> 欢迎各位大大走访我的<a href="https://likepoems.com" style="font-size:1.2rem;color:blue"><i>个人网站</i></a>
            </div>
          </div>
          <div class="box">
            <p class="infotitle">🛴博客介绍</p>
            <div class="checkbox minus blue checked">
              <input type="checkbox" checked="checked" />
              <p>本项目由 vue3 + element-ui + mongodb搭建而成</p>
            </div>
            <div class="checkbox minus blue checked">
              <input type="checkbox" checked="checked" />
              <p>本项目中的部分图片源于网洛</p>
            </div>
            <div class="checkbox minus blue checked">
              <input type="checkbox" checked="checked" />
              <p>本项目中的文章在转载时请注明出处</p>
            </div>
          </div>
          <div class="box">
            <p class="infotitle">🚁近期目标</p>
            <div class="checkbox minus yellow checked">
              <input type="checkbox" checked="checked" />
              <p>坚持每天刷一次前端面试题</p>
            </div>
            <div class="checkbox minus yellow checked">
              <input type="checkbox" checked="checked" />
              <p>坚持在每次上完课后进行知识点总结</p>
            </div>
            <div class="checkbox minus yellow checked">
              <input type="checkbox" checked="checked" />
              <p>坚持写博客</p>
            </div>
            <div class="checkbox minus yellow checked">
              <input type="checkbox" checked="checked" />
              <p>坚持记英语单词</p>
            </div>
          </div>
          <div class="box">
            <p class="infotitle">🔑技能学习</p>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>JavaScript</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>HTML5+CSS3</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>Vue</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>Bootstrap</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>微信小程序</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>Axure RP</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>MongoDB</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>MySQL</p>
            </div>
            <div class="checkbox green checked">
              <input type="checkbox" checked="checked" />
              <p>Element-ui</p>
            </div>
            <div class="checkbox plus red checked">
              <input type="checkbox" checked="checked" />
              <p>Node</p>
            </div>
            <div class="checkbox plus red checked">
              <input type="checkbox" checked="checked" />
              <p>D3</p>
            </div>
            <div class="checkbox plus red checked">
              <input type="checkbox" checked="checked" />
              <p>TypeScript</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "About",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTop
  },
   methods: {
    setpagedetail() {
      this.$refs.pagedetail.$refs.pagedetail.innerText = '关于'
    },
  },
  mounted() {
    this.setpagedetail();
  },
};
</script>

<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/14.png");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
  max-width: 1200px;
}
.layout.hide-aside {
  max-width: 1000px;
}
#page {
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
.fancybox img {
  border-radius: 50%;
  width: 110px;
  height: auto;
}
.authorname {
  font-size: 1.7rem;
  font-weight: 1000;
  background-image: linear-gradient(to right, #2980b9, #6dd5fa, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.authorinfo {
  font-weight: 700;
  color: #1a202c;
  font-size: 1.25em;
}
.box {
  margin-bottom: 0.8em;
}
.infotitle {
  font-weight: 700;
  color: #1a202c;
  font-size: 1.25em;
  margin-bottom: 0.15em;
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
}
</style>
